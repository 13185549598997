import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const BasketContext = createContext({
  delivery: [],
  totalPrice: 0,
  amount: 0,
  to: {},
});

export default function BasketProvider({ children }) {
  const [delivery, setDelivery] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [to, setTo] = useState({});

  const setNewTo = (newTo) => setTo(newTo);

  useEffect(() => {
    setDelivery(JSON.parse(localStorage.getItem('dishes')) || []);
    setTotalPrice(
      +localStorage.getItem('totalPrice') > 0
        ? +localStorage.getItem('totalPrice')
        : 0,
    );
    setAmount(
      +JSON.parse(localStorage.getItem('amount')) > 0
        ? +JSON.parse(localStorage.getItem('amount'))
        : 0,
    );
  }, []);

  const setNewDelivery = (dishes) => {
    setDelivery((prevDelivery) => {
      localStorage.setItem('dishes', JSON.stringify(dishes(prevDelivery)));

      return dishes(prevDelivery);
    });
  };

  const setNewTotalPrice = (newPrice) => {
    setTotalPrice((prevTotalPrice) => {
      localStorage.setItem('totalPrice', newPrice(prevTotalPrice));

      return +newPrice(prevTotalPrice);
    });
  };

  const setNewAmount = (newAmount) => {
    setAmount((prevAmount) => {
      localStorage.setItem('amount', newAmount(prevAmount));

      return newAmount(prevAmount);
    });
  };

  return (
    <BasketContext.Provider
      value={{
        delivery,
        totalPrice,
        amount,
        to,
        setNewDelivery,
        setNewTotalPrice,
        setNewAmount,
        setNewTo,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
}

BasketProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
