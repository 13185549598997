exports.components = {
  "component---src-pages-baked-js": () => import("./../../../src/pages/baked.js" /* webpackChunkName: "component---src-pages-baked-js" */),
  "component---src-pages-burgers-js": () => import("./../../../src/pages/burgers.js" /* webpackChunkName: "component---src-pages-burgers-js" */),
  "component---src-pages-certificates-js": () => import("./../../../src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-classic-js": () => import("./../../../src/pages/classic.js" /* webpackChunkName: "component---src-pages-classic-js" */),
  "component---src-pages-dodatki-js": () => import("./../../../src/pages/dodatki.js" /* webpackChunkName: "component---src-pages-dodatki-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-firm-js": () => import("./../../../src/pages/firm.js" /* webpackChunkName: "component---src-pages-firm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moti-js": () => import("./../../../src/pages/moti.js" /* webpackChunkName: "component---src-pages-moti-js" */),
  "component---src-pages-offer-agreement-js": () => import("./../../../src/pages/offer-agreement.js" /* webpackChunkName: "component---src-pages-offer-agreement-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-pickup-js": () => import("./../../../src/pages/pickup.js" /* webpackChunkName: "component---src-pages-pickup-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-sets-js": () => import("./../../../src/pages/sets.js" /* webpackChunkName: "component---src-pages-sets-js" */),
  "component---src-pages-sushi-js": () => import("./../../../src/pages/sushi.js" /* webpackChunkName: "component---src-pages-sushi-js" */),
  "component---src-templates-dish-js": () => import("./../../../src/templates/dish.js" /* webpackChunkName: "component---src-templates-dish-js" */)
}

